<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Auditorias</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Seguridad</li>
                  <li class="breadcrumb-item active">Auditorias</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- /.card-header -->
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-1">
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.auditable_id"
                          placeholder="Id"
                        />
                      </div>
                      <div class="col-md-2">
                        <v-select
                          class="form-control form-control-sm p-0"
                          v-model="modelo"
                          label="descripcion"
                          :options="listasForms.modelos"
                          placeholder="Modelos"
                          :class="[
                            $v.modelo.numeracion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                        ></v-select>
                      </div>
                      <div class="col-md-2">
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="slct_user"
                          placeholder="Usuario"
                          label="name"
                          :options="usuarios"
                          :filterable="false"
                          @search="buscarUsuarios"
                        ></v-select>
                      </div>
                      <div class="clas-md-2">
                        <select
                          v-model="filtros.evento"
                          class="form-control form-control-sm"
                        >
                          <option value="">Seleccione Evento</option>
                          <option value="created">Creación</option>
                          <option value="updated">Modificación</option>
                          <option value="deleted">Eliminación</option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          placeholder="Fecha Inicial"
                        />
                      </div>
                      <div class="col-md-2">
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          placeholder="Fecha Final"
                        />
                      </div>
                      <div class="col-md-1">
                        <button
                          class="btn btn-sm btn-primary"
                          @click="getIndex()"
                        >
                          Buscar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  id="auditorias"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>ID Auditable</th>
                      <th>Usuario</th>
                      <th>Modelo</th>
                      <th>Accion</th>
                      <th>Fecha</th>
                      <th style="width: 55%">Cambios</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in auditorias.data" :key="item.id">
                      <td>{{ item.auditable_id }}</td>
                      <td>{{ item.usuario.name }}</td>
                      <td>{{ item.auditable_type.slice(4) }}</td>
                      <td>
                        <div
                          v-if="
                            item.event != 'updated' && item.event != 'created'
                          "
                        >
                          <span class="badge badge-danger"
                            ><i class="far fa-trash-alt"></i> Eliminado</span
                          >
                        </div>
                        <div
                          v-else-if="
                            item.event != 'created' && item.event != 'deleted'
                          "
                        >
                          <span class="badge badge-primary"
                            ><i class="fas fa-pencil-alt"></i> Actualizado</span
                          >
                        </div>
                        <div v-else>
                          <span class="badge badge-secondary"
                            ><i class="fas fa-plus-circle"></i> Creado</span
                          >
                        </div>
                      </td>
                      <td>
                        <!-- {{ item.created_at.slice(0, 10) }} -->
                        {{ moment(item.created_at).format("YYYY-MM-DD") }}
                        {{ moment(item.created_at).format("HH:mm:ss") }}
                        <!-- {{ item.created_at.slice(11, 19) }} -->
                      </td>
                      <td>
                        <table
                          class="table table-sm table-striped table-bodered m-0"
                        >
                          <tbody
                            v-for="(dato, index) in item.changes"
                            :key="index"
                          >
                            <tr v-if="index < 2 || item.id == readMore">
                              <td class="bg-lightblue p-1" style="width: 30%">
                                <small
                                  ><strong>{{ dato[0] }}</strong></small
                                >
                              </td>
                              <td
                                style="
                                  max-width: 35%;
                                  min-width: 35%;
                                  hyphens: auto;
                                  word-wrap: break-word;
                                  word-break: break-word;
                                "
                                class="p-1"
                                :class="dato[1] ? 'bg-warning' : 'bg-secondary'"
                              >
                                <small>{{ dato[1] }}</small>
                              </td>
                              <td
                                style="
                                  max-width: 35%;
                                  min-width: 35%;
                                  hyphens: auto;
                                  word-wrap: break-word;
                                  word-break: break-word;
                                "
                                class="p-1"
                                :class="dato[2] ? 'bg-success' : 'bg-secondary'"
                              >
                                <small>{{ dato[2] }}</small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="text-right">
                          <a
                            v-if="item.changes.length > 2 && readMore == null"
                            href="#"
                            @click="setReadMore(item.id)"
                            >Ver Más</a
                          >
                          <a
                            v-if="readMore == item.id"
                            href="#"
                            @click="setReadMore(null)"
                            >Ver Menos</a
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="auditorias.total">
                  <p>
                    Mostrando del <b>{{ auditorias.from }}</b> al
                    <b>{{ auditorias.to }}</b> de un total:
                    <b>{{ auditorias.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="auditorias"
                  @pagination-change-page="getIndex"
                  :limit="7"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "Auditorias",
  components: {
    pagination,
    vSelect,
  },
  data() {
    return {
      filtros: {
        auditable_id: null,
        modelo: "",
        fecha_ini: null,
        fecha_fin: null,
        user_id: null,
      },
      slct_user: [],
      usuarios: [],
      modelo: "",
      usuario: "",
      readMore: null,
      auditorias: {},
      listasForms: {
        tipo_documentos: [],
        estados: [],
        modelos: [],
      },
      moment: moment,
    };
  },
  validations: {
    modelo: {
      numeracion: { required },
    },
  },
  methods: {
    getModelos() {
      axios.get("/api/lista/35").then((response) => {
        this.listasForms.modelos = response.data;
      });
    },

    buscarUsuarios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/usuarios/lista?usuario=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.usuarios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getIndex(page = 1) {
      if (!this.$v.modelo.$invalid) {
        this.filtros.user_id = null;
        if (this.slct_user) {
          this.filtros.user_id = this.slct_user.id;
        }
        this.filtros.modelo = this.modelo.cod_alterno;
        axios
          .get("/api/admin/Auditorias?page=" + page, {
            params: this.filtros,
          })
          .then((response) => {
            this.auditorias = response.data;
            this.getData();
          });
      } else {
        this.$swal({
          icon: "error",
          title: "e seleccionar un modelor",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    setReadMore(id) {
      this.readMore = id;
    },

    getData() {
      let data = this.auditorias.data;
      for (let i = 0; i < data.length; i++) {
        let oldValues = JSON.parse(data[i]["old_values"]);
        let newValues = JSON.parse(data[i]["new_values"]);
        let arrayChanges = new Array();
        let arrayKeys = new Array();
        if (oldValues || newValues) {
          if (oldValues.length == 0) {
            arrayKeys = Object.keys(newValues);
          } else {
            arrayKeys = Object.keys(oldValues);
          }
          let arrayOldValues = Object.entries(oldValues);
          let arrayNewValues = Object.entries(newValues);
          for (let j = 0; j < arrayKeys.length; j++) {
            let nValue = arrayNewValues.length > 0 ? arrayNewValues[j][1] : "";
            let oValue = arrayOldValues.length > 0 ? arrayOldValues[j][1] : "";
            arrayChanges.push([arrayKeys[j], oValue, nValue]);
          }
        }
        this.auditorias.data[i]["changes"] = arrayChanges;
      }
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getModelos();
    this.getMsg();
  },
};
</script>
